<template>
  <div class="fatal-error">
    <h1 class="fatal-error__title">
      {{ title }}
    </h1>
    <div class="fatal-error__desc">
      <p v-html="description"></p>
    </div>
    <div class="fatal-error__action">
      <Button @click.native="refreshPage">
        {{ action }}
      </Button>
    </div>
  </div>
</template>

<script>
import "./fatal-error.scss";
import Button from "@index/components/gui/button/Button";

import { mapGetters } from "vuex";

export default {
  name: "Error",
  methods: {
    refreshPage() {
      location.reload();
    },
  },
  computed: {
    ...mapGetters({
      options: "error/options",
    }),
    title() {
      return this.options.title || "Непредвиденная ошибка";
    },
    description() {
      return (
        this.options.description ||
        `При обработке запроса произошла ошибка.<br/>
                Попробуйте обновить страницу и повторить действие,
                либо обратитесь в службу поддержки.`
      );
    },
    action() {
      return this.options.action || "Обновить страницу";
    },
  },
  components: {
    Button,
  },
};
</script>
