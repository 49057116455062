<template>
  <ul class="progress">
    <li
      v-for="({ name }, index) in items"
      :key="index"
      class="progress__item"
      :class="{
        progress__active: name === $route.name,
      }"
    >
      <span>{{ index + 1 }}</span>
    </li>
  </ul>
</template>

<script>
import "./progress.scss";

export default {
  data() {
    return {
      items: [
        {
          name: "ContactInfo",
        },
        {
          name: "PersonalInfo",
        },
        {
          name: "Sign",
        },
        {
          name: "Card",
        },
      ],
    };
  },
};
</script>
