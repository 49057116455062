<template>
  <form @submit.prevent="validate" novalidate class="contact-information-view">
    <Calculator
      v-model="calculator"
      :showRangeInfo="2"
      class="contact-information-view__calc"
    />
    <Title class="contact-information-view__title"> Подать заявку </Title>
    <div class="contact-information-view__form">
      <ContactInformation
        ref="contactInformation"
        class="contact-information-view__form"
      />
      <div class="contact-information-view__agreement">
        <Checkbox :error="isSubmit && !agreement" v-model="agreement">
          <div v-html="acceptanceText"></div>
        </Checkbox>
        <Checkbox
          v-if="autoPaymentText"
          :error="isSubmit && !autoPayment"
          v-model="autoPayment"
        >
          <div v-html="autoPaymentText"></div>
        </Checkbox>
      </div>
      <div class="contact-information-view__actions">
        <Button type="submit"> Продолжить </Button>
      </div>
    </div>
  </form>
</template>

<script>
import sbg from "@index/mixins/sbg";
import Cookies from "js-cookie";

import "./contact-information-view.scss";

import Title from "@index/components/gui/title/Title";
import ContactInformation from "@index/components/application/contact-information/ContactInformation";
import Checkbox from "@index/components/common/checkbox/Checkbox";
import Button from "@index/components/gui/button/Button";

export default {
  name: "ContactInformationView",
  mixins: [sbg],
  data() {
    return {
      calculator: 10000,
      agreement: false,
      autoPayment: false,
      isSubmit: false,
    };
  },
  computed: {
    acceptanceText() {
      return this.$DICTIONARY.acceptanceText;
    },
    autoPaymentText() {
      return this.$DICTIONARY.acceptanceAutoPayment;
    },
  },
  created() {
    this.fieldFocusEvent = this.fieldFocusEvent.bind(this);
    this.autoPayment = this.agreement = this.isSbg;
  },
  mounted() {
    if (!this.isSbg) return;

    this.initScroll();

    setTimeout(() => {
      document.querySelectorAll("input").forEach((field) => {
        field.addEventListener("input", this.fieldFocusEvent);
      });
    });
  },
  methods: {
    initScroll() {
      window.onscroll = () => {
        const scrollHeight = Math.max(
          document.body.scrollHeight,
          document.documentElement.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.offsetHeight,
          document.body.clientHeight,
          document.documentElement.clientHeight
        );

        if (window.innerHeight + window.scrollY >= scrollHeight) {
          this.userScrollFullPage = true;
        }

        if (window.scrollY + 150 >= scrollHeight - innerHeight) {
          Cookies.set("notInterested", "1");
          const href = window.location.href;
          history.pushState(
            null,
            document.title,
            (this.siteConfig && this.siteConfig.noActionUrl) || "/final"
          );
          history.pushState(null, document.title, href);
          window.onpopstate = () => {
            location.reload();
          };
          window.onscroll = null;
        }
      };
    },
    fieldFocusEvent() {
      Cookies.set("notInterested", "0");
      window.onscroll = null;
    },
    validate() {
      this.isSubmit = true;
      this.$refs.contactInformation.validate();

      ((this.autoPaymentText && this.autoPayment) || !this.autoPaymentText) &&
        this.$refs.contactInformation.isValid &&
        this.agreement &&
        this.submit();
    },
    async submit() {
      const { checkPhoneByCode, noValid } = await this.$store.dispatch(
        "application/send",
        {
          contactData: this.$refs.contactInformation.form,
        }
      );

      if (noValid && Object.keys(noValid)) {
        const formErrors = this.$refs.contactInformation.formErrors;

        formErrors.phone =
          (noValid.phone === false && "Невалидное значение") || "";
        formErrors.email =
          (noValid.email === false && "Невалидное значение") || "";

        return;
      }

      if (!checkPhoneByCode) {
        window.location.href = "/poll/passport/";
        // this.$router.push({ name: 'PersonalInfo' })
      } else {
        this.$router.push({ name: "Auth" });
      }
    },
  },
  components: {
    Title,
    ContactInformation,
    Checkbox,
    Button,
    Calculator: () => import("@index/components/common/calculator/Calculator"),
  },
};
</script>
